export const frecuencies = [
  { label: "Diaria", value: "D", days: 0 },
  { label: "Semanal", value: "W", days: 7 },
  { label: "Quincenal", value: "Q", days: 15 },
  { label: "Mensual", value: "M", days: 30 },
  { label: "Trimestral", value: "QT", days: 90 },
  { label: "Semestral", value: "B", days: 180 },
  { label: "Anual", value: "A", days: 365 },
  { label: "Rango de fechas", value: "DR", days: 0 },
];

export const types = [
  { label: "Cursos", value: "C" },
  { label: "Ventas", value: "S" },
];

export const typeOptions = [
  { label: "Cantidad", value: "Q" },
  { label: "Monto", value: "A" },
  { label: "Top", value: "T" },
];

export const typeRewards = [
  { label: "Porcentaje", value: "P" },
  { label: "Monto fijo", value: "FA" },
];

export const roles = [
  { label: "Vendedor BSmart", value: "Vendedor BSmart" },
  { label: "Mayorista", value: "Mayorista" },
  { label: "Mayorista Vendedor", value: "Mayorista Vendedor" },
  { label: "Distribuidor", value: "Distribuidor" },
  { label: "Subdistribuidor", value: "Subdistribuidor" },
];